.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-container{
  height: 100vh;
  width: 100%;
}

.clicked-coord-label{
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 5px;
}

.clicked-coord-label p {
  margin: 10px;
}

.navbar-custom{
  background-color: #483930;
  color: white;
}

.navbar-custom .dropdown-toggle{
  color: white;
}

.mapboxgl-ctrl-group {
  width: 30px;
  margin: 10px 0 0 10px;
}

.mapboxgl-ctrl-scale {
  position: absolute;
  bottom: 8px;
  left: 120px;
}

.mapboxgl-canvas {
  left: 0;
}

#layercontrol{
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 10px;
  background-color: white;
  width:200px;
  height: 100px;
  border-radius: 6px;
  border: 2px solid rgb(165, 165, 165);
}

.layerlist{
  list-style-type: none;
  padding: 5px;
  font-size:12px;
}

.layerlist label{
  padding-left: 5px;
}

#legend{
  position: absolute;
  left: 10px;
  bottom: 40px;
  padding: 10px;
  background-color: white;
  width:200px;
  height: 100px;
  border-radius: 6px;
  border: 2px solid rgb(165, 165, 165);
}

.legend label,
.legend span {
  display:block;
  float:left;
  height:15px;
  width:20%;
  text-align:center;
  font-size:9px;
  color:#808080;
  }